export type AppLoadingContent = {
  title: string | undefined;
  message: string | undefined;
  showTrustPilot?: boolean;
  delay?: boolean;
  clickLeadsLoadingData?: Record<string, unknown>;
};

export type AppLoadingDefaultContentTypes = 'default' | 'prefil' | 'resume';

export const APP_LOADING_CONTENT: Record<AppLoadingDefaultContentTypes, AppLoadingContent> = {
  default: {
    title: undefined,
    message: undefined,
  },
  prefil: {
    title: 'Getting Your Quote Ready',
    message: "We're pre-filling your details. You'll just need to provide a bit more information to get your personalized quote.",
    showTrustPilot: true,
    delay: true,
  },
  resume: {
    title: 'Resuming Your Application',
    message: "We're loading your application. You may need to provide a few more details to finalize your quote or purchase your policy.",
    showTrustPilot: true,
    delay: true,
  },
};
