import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APP_LOADING_CONTENT, type AppLoadingContent } from './app-loader-content';

/**
 * Application Level Loader Service
 * - manages loading state of the application
 */
@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  /**
   * Indicates that loader is "active" or on
   */
  private _isActive$ = new BehaviorSubject(false);
  public isActive$ = this._isActive$.asObservable();

  /**
   * Content is what is displayed to the user
   */
  private _content$ = new BehaviorSubject<AppLoadingContent>(APP_LOADING_CONTENT.default);
  public content$ = this._content$.asObservable();

  /**
   * Method to turn the loader on or set to an active state
   * @param info
   * @param isLong
   */
  public on(content: AppLoadingContent = APP_LOADING_CONTENT.default) {
    this._content$.next(content);
    this._isActive$.next(true);
  }

  /**
   * Turns the loader off or sets it to a de-active state
   */
  public off() {
    this._isActive$.next(false);
  }

  public switch(isOn: boolean, content: AppLoadingContent = APP_LOADING_CONTENT.default) {
    if (isOn) {
      this.on(content);
    } else {
      this.off();
    }
  }
}
